import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNavMain from '../containers/NewTopNav3';
import TopNav from '../containers/NewTopNavNFTs4';
import TopNav3 from '../containers/NewTopNav3NFTMobile';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import imgSampleFace from '../assets/img/nfts/img-51-usa-full.png'
import allNFTs from '../data/nfts'
import allNFTCountries from '../data/nftCountries'
import nftOverview2 from '../data/nftOverview2';
import useNfts from '../hooks/useNfts';
import { WALLET_URL } from '../constants/defaultValues';

import { Service } from '../providers/Services';

// CSS
import '../assets/css/NFTs.css'

class NFTs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fade: false,
      activeCol: -1,
      page: 1,
      perPage: 100,
      data: null,
      paginatedData: [],
      loadMoreData: [],
      totalPages: null,
      maxPage: null,
      type: 'ALL',
      allCountries: [],
      selectedCountry: 'ALL',
      width: window.innerWidth,
      height: window.innerHeight,
      loading: true,
      isMobile: false
    };
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  readDetails = (e, key) => {
    e.preventDefault();
    this.setState({activeCol: key})
  }

  closeReadDetails = () => {
    this.setState({activeCol: -1})
  }

  get = (page, perPage, type = 'ALL', country = 'ALL') => {

    let finalData;

    finalData = this.state.data;

    if (type != 'ALL') {
        finalData = finalData.filter(item => item.type === type)
        if (page == 1 && this.state.loadMoreData.length > 0) {
            this.setState({ loadMoreData: [] })
        }
    }
    if (country != 'ALL') {
        finalData = finalData.filter(item => item.country === country)
        if (page == 1 && this.state.loadMoreData.length > 0) {
            this.setState({ loadMoreData: [] })
        }
    }

    if (page == 1 && this.state.loadMoreData.length > 0) {
        this.setState({ loadMoreData: [] })
    }

    const maxPages = Math.ceil(finalData.length / perPage);

    finalData = finalData.slice((page - 1) * perPage, page * perPage)

    // customConsole.log('maxPages', maxPages)

    // If Load More Add New Data
    if (this.state.isMobile) {
        this.setState((prevState) => {
            const updatedLoadMoreData = [...prevState.loadMoreData, ...finalData];
            return {
                loadMoreData: updatedLoadMoreData,
                paginatedData: updatedLoadMoreData,
                totalPages: maxPages
            };
        });
    } else {
        // If not change the record 
        this.setState({paginatedData: finalData})
    }
  }

  getPages = (type = 'ALL', country = 'ALL') => {
    let pages;
    let count;
    let finalData;

    finalData = this.state.data;
    // if (type == 'ALL' && country == 'ALL') {
    //     pages = Math.ceil(this.state.data.length / this.state.perPage);
    // } else {
    //     pages = Math.ceil(this.state.data.filter(item => item.category === type).length / this.state.perPage);
    // }
    if (type != 'ALL') {
        finalData = finalData.filter(item => item.type === type)
    }
    if (country != 'ALL') {
        finalData = finalData.filter(item => item.country === country)
    }
    count = finalData.length 
    pages = Math.ceil(count / this.state.perPage);
    this.setState({pages});
  }
  getCountries = () => {
    let countries = allNFTCountries.sort((a, b) => {
        // Sort in ascending order
        if (a.name < b.name) {
        return -1;
        }
        if (a.name > b.name) {
        return 1;
        }
        return 0;
    });
    this.setState({allCountries: countries})
  }

  handlePageChange = (event, newPage) => {
    this.setState({page: newPage})
    // Update record
    this.get(newPage, this.state.perPage, this.state.type)
  };

  handlePageNext = (event) => {
    const newPage = this.state.page + 1
    if (newPage > this.state.pages) return false;
    // Set new page count
    this.setState({page: newPage})
    // Update record
    this.get(newPage, this.state.perPage, this.state.type)
  };

  handleLoadMore = (event) => {
    event.preventDefault()

    const newPage = this.state.page + 1

    if (newPage > this.state.pages) return false;

    // Set new page count
    this.setState({page: newPage})

    // Update record
    this.get(newPage, this.state.perPage, this.state.type, this.state.selectedCountry, true)
  };
  handlePagePrevious = (event) => {
    const newPage = this.state.page - 1
    if (newPage == 0) return false;
    // Set new page count
    this.setState({page: newPage})
    // Update record
    this.get(newPage, this.state.perPage, this.state.type)
  };
  handleTypeFilter = (event, type) => {
    // Update pagination
    this.getPages(type, this.state.selectedCountry);
    // Update pagination value to 1
    this.setState({page: 1})
    // Set new page type
    this.setState({type: type})
    // Update record
    this.get(1, this.state.perPage, type, this.state.selectedCountry)
  };
  handleCountryFilter = (e) => {
    let val = e.target.value 
    // Update pagination
    this.getPages(this.state.type, val);
    // Update pagination value to 1
    this.setState({page: 1})
    // Update selected country
    this.setState({ selectedCountry: val });
    // Update record
    this.get(1, this.state.perPage, this.state.type, val)
  }
  handleCountryClickFilter = (e, val) => {
    // Update pagination
    this.getPages(this.state.type, val);
    // Update pagination value to 1
    this.setState({page: 1})
    // Update selected country
    this.setState({ selectedCountry: val });
    // Update record
    this.get(1, this.state.perPage, this.state.type, val)
  }
  formatID = (num) => {
    if (num >= 1 && num < 10) {
        return '000' + num;
    }
    else if (num >= 10 && num < 100) {
        return '00' + num;
    } else if (num > 100 && num < 1000) {
        return '0' + num;
    } else {
        return num;
    }
  }
  getCountryDetails(countryName) {
    const data = allNFTCountries.find(country => country.name.toLowerCase() === countryName.toLowerCase());

    if (data) {
        return data
    } else {
        return {}
    }
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  componentDidMount() {
    Service.getNFTs()
    .then(res => {

        if (this.state.width < 601) {
            this.setState({
                perPage: 4
            });

            this.setState({
                isMobile: true
            });
        }

        const maxPages = Math.ceil(res.length / this.state.perPage);

        // Get Data
        this.setState({ data: res })
        this.setState({ totalPages: maxPages })

        this.get(this.state.page, this.state.perPage);

        // Get pages
        this.getPages()

        // Get Countries
        this.getCountries()
        
        // Set Loading False
        this.setState({ loading: false })
    })
    .catch(err => {
        // custoMconsole.log('NFTS ERR#####', err)
        this.setState({ data: [] })
    })

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { t } = this.props;
    const { width, height } = this.state;

    return (
        <>
      <div className='v3-main v3-main nfts-main v3-nfts-main'>

        <div className='v3-top-nav-main-top'>
            <TopNavMain />
        </div>

        {/* HEADER */}
        { width < 1200 && <TopNav3 selectedCountry={this.state.selectedCountry} handleCountryClickFilter={this.handleCountryClickFilter} />}
        { width >= 1200 &&  <TopNav selectedCountry={this.state.selectedCountry} handleCountryClickFilter={this.handleCountryClickFilter} /> }
        
         {/* SECTION 2 */}
         <section className='nfts-curve nfts-2'>
            <Container>
                <Row className='align-center'>
                    <Col>
                        { this.state.pages > 0 && 
                            <ul className='nfts-pagination'>
                                <li><a href='#' onClick={e => this.handlePagePrevious(e)}>{'<'}</a></li>
                                {Array.from({ length: this.state.pages }).map((_, i) => (
                                    <li><a className={`${this.state.page == (i + 1) ? "active" : ""}`} href='#' onClick={e => this.handlePageChange(e, i + 1)}>{i + 1}</a></li>
                                ))}
                                <li><a href='#' onClick={e => this.handlePageNext(e)}>{'>'}</a></li>
                            </ul>
                        }
                    </Col>
                    <Col>
                        <ul class='nfts-categories'>
                            <li><a onClick={e => this.handleTypeFilter(e, 'ALL')} href='#' className={`${this.state.type == 'ALL' ? 'active' : ''}`}>All</a></li>
                            <li><a onClick={e => this.handleTypeFilter(e, 1)} href='#' className={`${this.state.type == 1 ? 'active' : ''}`}>Small</a></li>
                            <li><a onClick={e => this.handleTypeFilter(e, 2)} href='#' className={`${this.state.type == 2 ? 'active' : ''}`}>Medium</a></li>
                            <li><a onClick={e => this.handleTypeFilter(e, 3)} href='#' className={`${this.state.type == 3 ? 'active' : ''}`}>Large</a></li>
                        </ul>
                    </Col>
                    <Col>
                        <div className='nfts-country-filter'>
                            <label>Country Filter</label>
                            <select onChange={e => this.handleCountryFilter(e)}>
                                <option value='ALL'>ALL COUNTRIES</option>
                                {this.state.allCountries.map(item => (
                                    <option selected={item.name === this.state.selectedCountry} value={item.name}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        
                        <div className='nfts-wrap'>
                            { this.state.paginatedData.length > 0 && this.state.paginatedData.map((item, key) => (
                                <div onMouseLeave={this.closeReadDetails} className={ this.state.activeCol === key ? 'nfts-col active' : 'nfts-col'}>
                                    <div className={`nfts-col-inner ${item.foundersClub ? 'founders-club' : ''}`}>
                                        {/* FRONT */}
                                        <div className='nfts-col-inner-front'>
                                            <div className='nfts-col-img'>
                                                <img src={ require(`../assets/img/nft-images/${item.nft_name}.png`).default } />
                                            </div>
                                            <div className='nfts-col-footer'>
                                                <div className='nfts-col-footer-left'>
                                                    <span className='nfts-col-footer-num'>{this.formatID(item.nft_number)}</span>
                                                    <span className='nfts-col-footer-name' title={item.country}>{item.country}</span>
                                                </div>
                                                <div className='nfts-col-footer-right'>
                                                    <a href='#' onClick={(e) => this.readDetails(e, key)} className='nfts-col-footer-btn'>Read Details</a>
                                                </div>
                                                
                                            </div>
                                             {/* SOLD */}
                                             {(item.status == 1 || item.status == 2) && (
                                                <div className={`nft-sold-wrap ${item.status === 2 ? 'in-progress' : ''}`}>
                                                    <div className={`nft-sold-text ${item.status === 2 ? 'v2-font-18 v2-m-font-16' : ''}`}>{ item.status == 2 ? 'In Progress' : 'Sold' }</div>
                                                </div>
                                             )}
                                        </div>
                                        {/* BACK */}
                                        <div className='nfts-col-inner-back v3-nfts-col-inner-back'>
                                            {/* HEADER */}
                                            <div className='nfts-col-inner-back-header'>
                                                <div className='nfts-col-inner-back-header-left'>
                                                    <span className='nfts-col-inner-back-header-left-num'>{this.formatID(item.nft_number)}</span>
                                                    <span className='nfts-col-inner-back-header-left-name'>{item.country}</span>
                                                </div>
                                                <div className='nfts-col-inner-back-header-right'>
                                                    <span className='nfts-col-inner-back-header-right-price'>${item.price}</span>
                                                </div>
                                            </div>
                                            {/* BODY */}
                                            <div className='nfts-col-inner-back-body'>
                                                <div>
                                                    <div className='nft-lvl-wrap'>
                                                        <div className='nft-lvl-header'>
                                                            <span>Earnings</span>
                                                            <span className='text-uppercase'>Level 1</span>
                                                        </div>

                                                        <ul className='nft-lvl-lists'>
                                                            <li>Wallets: $2 - $20</li>
                                                            <li>Membership: 25%</li>
                                                            <li>NFTs: {item.nftL1}</li>
                                                            <li>Merchants: $10 - $100</li>
                                                            <li>Royalties: {item.retailL1}%</li>
                                                        </ul>
                                                    </div>

                                                    <div className='v2-mt-0-25 nft-lvl-wrap'>
                                                        <div className='nft-lvl-header'>
                                                            <span>Earnings</span>
                                                            <span className='text-uppercase'>Level 2</span>
                                                        </div>

                                                        <ul className='nft-lvl-lists'>
                                                            <li>Wallets: 20%</li>
                                                            <li>Membership: 20%</li>
                                                            <li>NFTs: {item.nftL2}</li>
                                                            <li>Merchants: $25</li>
                                                            <li>Royalties: {item.retailL2}%</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* FOOTER */}
                                            {item.status == 0 && (
                                                <div className='nfts-col-inner-back-footer'>
                                                    <a href={`/nfts/${this.formatID(item.id)}`} className='nfts-col-inner-back-footer-btn v3-nfts-col-inner-back-footer-btn'>VIEW MORE</a>
                                                </div>
                                             )}
                                            
                                        </div>
                                    </div>
                                </div>
                            )) }
                            { (this.state.paginatedData.length == 0 && !this.state.loading) && 
                                <p className='nft-record-coming-soon'>Coming Soon...</p>
                            }
                            { (this.state.loading) && 
                                <p className='nft-record-coming-soon'>Loading...</p>
                            }
                        </div>
                        { this.state.pages > 0 && 
                            <ul className='nfts-pagination'>
                                <li><a href='#' onClick={e => this.handlePagePrevious(e)}>{'<'}</a></li>
                                {Array.from({ length: this.state.pages }).map((_, i) => (
                                    <li><a className={`${this.state.page == (i + 1) ? "active" : ""}`} href='#' onClick={e => this.handlePageChange(e, i + 1)}>{i + 1}</a></li>
                                ))}
                                <li><a href='#' onClick={e => this.handlePageNext(e)}>{'>'}</a></li>
                            </ul>
                        }
                    </Col>
                </Row>
                { (this.state.page < this.state.totalPages) &&
                    <Row className='mobile'>
                        <Col>
                            <div className='btn-wrap'>
                                <div class="btn-wrap v3-wallet-btn-wrap">
                                    <a onClick={e => this.handleLoadMore(e)} class="v3-wallet-btn" href="#"><span>Load More</span></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
            </Container>
         </section>
        
        {/* FOOTER */}
        <BottomNav />
      </div>
      </>
    );
  }
}
export default withTranslation()(NFTs);