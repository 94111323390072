import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Helpers
import { withAffCodeURL } from '../helpers/helpers';

// CSS
import '../assets/css/FinancialOverview.css';

// Images
import imgOvato from '../assets/img/financial-overview/img-ovato.png'
import imgUtility from '../assets/img/financial-overview/img-utility.png'
import imgSpecificVerticals from '../assets/img/financial-overview/img-specific-verticals.png'
import imgAffiliate from '../assets/img/financial-overview/img-affiliate.png'
import imgBitovation from '../assets/img/financial-overview/img-bitovation-solution.png'
import imgRoadMap from '../assets/img/financial-overview/img-roadmap.png'
import imgQ1 from '../assets/img/financial-overview/img-q1.png'
import imgQ2 from '../assets/img/financial-overview/img-q2.png'
import imgQ3 from '../assets/img/financial-overview/img-q3.png'
import imgQ4 from '../assets/img/financial-overview/img-q4.png'
import imgDAO from '../assets/img/financial-overview/img-ovato-dao.png'
import imgOutlook from '../assets/img/financial-overview/img-outlook.png'
import imgVideo from '../assets/img/financial-overview/img-video.png'
import imgScan from '../assets/img/financial-overview/img-scan-qr.png'
import imgScanMobile from '../assets/img/financial-overview/img-scan-mobile.png'
import QRFinancialOverview from '../components/qrcode/QRFinancialOverview';

// Components


class FinancialOverview extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='financial-main'>     

        {/* Section 1*/}
        <section className='v2-pb-18 v2-pt-6 v2-m-pb-16 v2-m-pt-4 financial-1'>
            <Container>
                <Row>
                    <Col>
                        <div className='v2-ml--2 v2-m-ml-0 v2-mb--1 v2-tp-text-center img-wrap'>
                            <a href={withAffCodeURL('')}><img className='financial-1-img' width={129} src={imgOvato} /></a>
                        </div>
                        <h1 className='mt-0 poppins v2-font-62 v2-tl-font-48 v2-tp-font-40 v2-m-font-28 semi-bold white-text lh-1-2 mb-0 v2-tp-text-center v2-m-mt-2'>Financial Overview</h1>
                        <p className='poppins v2-font-39 v2-tl-font-28 v2-m-font-22 v2-m-mt-0-5 semi-bold white-text lh-1-2 v2-tp-text-center'>and Roadmap 2024</p>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 2*/}
        <section className='v2-mt--1 v2-pb-0 financial-2 v2-tl-pb-4 v2-m-pt-3'>
            <Container className='relative z-index-2'>
                <Row>
                    <Col sm={8} className='v2-tp-col-12 v2-pr-4 v2-m-pr-1'>
                        <div className='v2-mt--14 video-wrap v2-tp-text-center fo-2-video-wrap'>
                            <a href='#'><img src={imgVideo} /></a>
                        </div>

                        <h2 className='poppins v2-font-36 v2-tp-font-32 v2-m-font-28 bold dark-text-5 v2-tp-text-center v2-mt-3'>Overview</h2>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-m-text-left'>Ovato has developed a new form of money that is fully secured over the blockchain, providing lightning-fast transactions in about one second for both parties. Originally built as a "Layer 1" coin, Ovato has successfully forked onto the Ovato Protocol built on Polygon. This new protocol syncs up with our NFT solution, allowing users to earn transactions that are fully settled over the blockchain in about ten seconds. With its suite of unique product lines for users, merchants, and industry, combined with an experienced base of knowledge acquired over the last 3 years of its development, Ovato is in the perfect position to win real utility in specific verticals. The positioning of our brand is to provide a better, more profitable way to exchange value while instilling trust and service in the currency on a decentralized basis</p>

                        <div className='v2-mt-2-5 financial-blue-box'>
                            <p className='poppins v2-font-15 v2-tl-font-14 bold dark-text-5 text-center lh-1-2 v2-mb-0-5'>Ovato has learned that the key to winning the race to utility is to</p>
                            <p className='poppins v2-font-24 v2-tl-font-22 v2-m-font-18 bold pink-text-5 text-center lh-1-2'>fulfill the need rather than create the need!</p>
                        </div>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-2-5 v2-m-text-left'>Ovato has identified approximately 26 unique industries that need an alternative to their current banking situation. For example, the cannabis industry is approximately a $15 billion industry and is legal in 37 states in the United States. However, at the federal level, banking is still illegal. This virtually restricts every dispensary to using cash only and denies access to all banking rails. Ovato has built a proprietary process to provide a legal platform to help dispensaries adopt it as a form of payment and then a viable method to onboard banking. Ovato has identified 26 similar industries with fractures and inefficiencies, shaping the next frontier of permissionless spending.</p>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>While Ovato is 100% committed to utility for its coin, it ensures that its network is run for the long term by charging users a 1% transaction fee to fuel the network and provide continuing royalties for early adopters and affiliates. Additionally, a portion of these funds is allocated to the Ovato DAO to enhance mining and contribute to charitable causes in the community where it was generated.</p>

                        <div className='btn-wrap v2-mt-4 v2-tp-text-center'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn'>Get a basic wallet</a>
                        </div>
                    </Col>

                    <Col sm={4} className='v2-tp-col-12'>
                        <div className='v2-mt--6 v2-tp-mt-3 financial-white-box'>
                            <div className='financial-text-wrap'>
                                <div className='text-1'>online</div> 
                                <div className='text-2'>ovato.com</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>symbol</div> 
                                <div className='text-2'>ovo</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>maximum outstanding</div> 
                                <div className='text-2'>950 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>current outstanding</div> 
                                <div className='text-2'>344.4 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>public float</div> 
                                <div className='text-2'>15 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>stack/locking</div> 
                                <div className='text-2'>75 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>public float</div> 
                                <div className='text-2'>3 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>general lock</div> 
                                <div className='text-2'>200 million</div>
                            </div>

                            <div className='financial-text-wrap financial-text-wrap-2'>
                                <div className='text-1'>Coin Concentration Link</div>
                                <div className='text-1'><a href={withAffCodeURL('https://ovato.com/en/top-100-holders')} target='_blank'>https://ovato.com/en/top-100-holders</a></div>
                            </div>

                            <div className='financial-text-wrap financial-text-wrap-2'>
                                <div className='text-1'>Economy Overview</div>
                                <div className='text-1'><a href={withAffCodeURL('https://ovato.com/en/landing')} target='_blank'>https://ovato.com/en/landing</a></div>
                            </div>

                            <div className='financial-text-wrap financial-text-wrap-2'>
                                <div className='text-1'>Get a Wallet</div>
                                <div className='text-1'><a href={withAffCodeURL('https://wallet.ovato.com')} target='_blank'>https://wallet.ovato.com</a></div>
                            </div>
                        </div>

                        <div className='v2-mt-2 financial-white-box financial-white-box-2'>
                            <h3 className='poppins v2-font-22 v2-m-font-20 bold dark-text-5'>Get Started</h3>

                            <ul className='financial-ul'>
                                <li>Get a Wallet</li>
                                <li>Receive some money </li>
                                <li>Discover the Opportunity <br /> <a href={withAffCodeURL('https://govato.io/')} target='_blank'>(govato.io)</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 3 */}
        <section className='white-top-curve-12 financial-3 v2-pt-16 v2-tl-pt-10 v2-tp-pt-8 v2-m-pt-6 v2-pb-8 v2-tl-pb-6 v2-tp-pb-4'>
            <Container>
                <Row className='align-center'>
                    <Col sm={6} className='v2-tp-col-12 v2-tp-order-1 v2-tp-mt-2'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 v2-tp-text-center'>Utility Will Win The Race</h2>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>"Real-world" utility has always been Ovato's focus, and we believe this virtually untapped marketplace will ultimately decide the race. While Bitcoin remains the bellwether of our industry for "storage of value," we believe that utility will be the most disruptive element to truly bring in the masses. Ovato has identified 26 unique industries that need an alternative to their current banking situation. For example, the cannabis industry is approximately a $15 billion industry and is legal in 37 states in the USA. However, banking is still illegal for this industry at the federal level, restricting dispensaries to cash-only transactions. Ovato has built a proprietary process to fix this problem by accepting and using Ovato's platform.</p>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>To maintain our network and ensure long-term survivability, Ovato charges users a 1% transaction fee on both buy-side and sell-side transactions, totaling a network fee of 2%. This fee is spread across the network and fully administered by the community (DAO), fueling the network and providing continuing royalties for our early adopters and affiliates.</p>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12'>
                        <div className='img-wrap mx-auto v2-tp-mw-50 v2-m-mw-80'>
                            <img src={imgUtility} />
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-6 align-center'>
                    <Col sm={6} className='v2-pr-4 v2-tp-col-12'>
                        <div className='img-wrap v2-tp-mw-50 mx-auto v2-m-mw-100'>
                            <img src={imgSpecificVerticals} />
                        </div>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12 v2-tp-mt-4'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 v2-tp-text-center'>Specific Verticals</h2>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>As mentioned, Ovato has identified 26 specific industries where traditional banking is either unavailable or deemed extremely high-risk for various reasons. These industries range from "High Risk" to "Illegal Aliens," where everyday banking is simply broken. While banking may be legal for these businesses/individuals, it may not be economically practical due to credit card or processing fees ranging anywhere from 9% to 18%. Ovato has developed unique solutions to not only address these fractures and inefficiencies but also provide additional revenue streams for these businesses and individuals by onboarding their existing customer base and peers.</p>

                        <div className='btn-wrap v2-mt-3 v2-tp-text-center'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn'>Get a basic wallet</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 4 */}
        <section className='financial-4 v2-pt-16 v2-tl-pt-14 v2-pb-8 v2-tp-pb-6 v2-m-pb-4 v2-tp-pt-12 v2-m-pt-6'>
            <Container>
                <Row className='align-center'>
                    <Col sm={6} className='v2-tp-col-12 v2-tp-order-1 v2-tp-mt-3'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold white-text v2-tp-text-center'>Affiliate and Influencer Army</h2>

                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>To facilitate outreach and engagement, Ovato has launched Govato (govato.io), a centralized affiliate marketing platform. Govato provides a forum for users to share the Ovato ecosystem with like-minded businesses and consumers by offering compelling economic incentives for participants. Govato then offers a suite of tools to assist participants, including trackable links and daily content to help grow the network. The plan calls for the launch of Govato in Q2 and encourages influencers to have their followers simply download a wallet. Once done, this brings the new user into the ecosystem, yielding ongoing royalties.</p>

                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>Additionally, Govato introduces a line of NFTs leveraging cutting-edge technology fully integrated into the Ovato coin ecosystem. These unique NFTs provide holders with protected territories, rights, and income-producing royalties. Moreover, each NFT contains wrapped OVO, enabling our affiliate network to earn tangible equity and offering an additional exit strategy for participants.</p>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12'>
                        <div className='v2-pl-4 img-wrap v2-mr--4 v2-tl-mr-0 v2-tp-mw-50 v2-m-mw-80 mx-auto v2-tp-pl-0'>
                            <img src={imgAffiliate} />
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-6 align-center'>
                    <Col sm={6} className='v2-pr-4 v2-tp-col-12 v2-m-pr-1'>
                        <div className='img-wrap v2-tp-mw-50 mx-auto v2-m-mw-70'>
                            <img src={imgBitovation} />
                        </div>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12 v2-tp-mt-3'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold white-text v2-tp-text-center'>Bitovation Solution</h2>

                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>Bitovation, as our strategic and cooperative stakeholder, offers a suite of products and services to the industry. Bitovation is an information and technology company helping crypto users and services with the tools necessary to enter or store crypto. Bitovation will utilize Ovato as its bridge and onboarding coin for these users.</p>

                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>Bitovation is dedicated to empowering Money Service Providers (MSPs) with the tools they need to seamlessly integrate cryptocurrencies into their services.</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='text-center btn-wrap v2-mt-6 v2-m-mt-4'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn'>Get a basic wallet</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 5 */}
        <section className='financial-5'>
            <Container>
                <Row>
                    <Col className='financial-5-col-1'>
                        <h2 className='relative z-index-2 v2-mt-6 poppins v2-font-63 v2-tl-font-48 v2-tp-font-36 v2-m-font-32 bold blue-text-7 v2-pl-6 v2-tp-pl-2 v2-m-pl-0 v2-m-text-left v2-m-mw-50 v2-m-mt-4'>Roadmap 2024</h2>

                        <div className='img-wrap financial-5-img-step desktop'>
                            <img src={imgRoadMap} />
                        </div>

                        {/* Q1 */}
                        <div className='financial-step-wrap fsw-1'>
                            <ul className='financial-step-ul'>
                                <li>Migrate Protocol</li>
                                <li>Halt trading </li>
                                <li>Complete Fork</li>
                                <li>Bitovation front end positioning</li>
                            </ul>

                            <div className='img-wrap'>
                                <img src={imgQ1} />
                            </div>
                        </div>

                        {/* Q2 */}
                        <div className='financial-step-wrap fsw-2'>
                            <ul className='financial-step-ul'>
                                <li>Basic and Premium Wallets launched</li>
                                <li>Re-List Ovato 2.0 on Exchanges P2P B2B, Profit, Bank</li>
                                <li>Launch Bitovation Exchange Services</li>
                                <li>Launch Govato Affiliate Engine</li>
                                <li>Launch NFTs on Secondary Markets such as OpenSea</li>
                            </ul>

                            <div className='img-wrap'>
                                <img src={imgQ2} />
                            </div>
                        </div>

                        {/* Q3 */}
                        <div className='financial-step-wrap fsw-3'>
                            <ul className='financial-step-ul'>
                                <li>Full swing in promotional campaign</li>
                                <li>Version 2.2 Apps</li>
                                <li>Implantation of DAO</li>
                                <li>Community Rewards</li>
                            </ul>

                            <div className='img-wrap'>
                                <img src={imgQ3} />
                            </div>
                        </div>

                        {/* Q4 */}
                        <div className='financial-step-wrap fsw-4'>
                            <ul className='financial-step-ul'>
                                <li>Layer 2 back Layer 1</li>
                                <li>Lightening network</li>
                                <li>Mining Protocol implemented with field</li>
                                <li>Mining hardware for field</li>
                            </ul>

                            <div className='img-wrap'>
                                <img src={imgQ4} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-12 v2-m-mt-4'>
                    <Col>
                        <div className='text-center btn-wrap'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn'>Get a basic wallet</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 6 */}
        <section className='v2-pt-8 v2-tp-pt-4 financial-6 v2-pb-8 v2-tl-pb-6 v2-tp-pb-4'>
            <Container>
                <Row className='align-center'>
                    <Col sm={7} className='v2-tp-col-12 v2-tp-order-1 v2-tp-mt-2'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 v2-tp-text-center'>Ovato Dao <span className='v2-font-17 v2-tl-font-15 regular v2-m-block'>(Decentralized Autonomous Organization)</span></h2>
                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>Ovato is pioneering a new approach to governance through the implementation of a Decentralized Autonomous Organization (DAO). This DAO empowers Ovato coin holders to collectively participate in decision-making processes regarding the cryptocurrency's future development, direction, and the allocation of funds, including a commitment to allocate approximately 1% of these transactions to climate change initiatives. Through smart contracts and blockchain technology, Ovato's DAO enables transparent and decentralized decision-making, ensuring that all stakeholders have a voice in the network's governance for the future. Additionally, coin holders can propose and vote on various initiatives, such as protocol upgrades, marketing strategies, and community initiatives. This democratic structure fosters community engagement and consensus-building, ultimately enhancing the utility and value proposition of the Ovato cryptocurrency. With the DAO, Ovato is pioneering a model of decentralized governance that aligns with the principles of transparency, inclusivity, and innovation in the cryptocurrency space.</p>
                    </Col>

                    <Col sm={5} className='v2-tp-col-12'>
                        <div className='img-wrap v2-mr--12 v2-tl-mr-0 v2-tp-mw-50 v2-m-mw-90 v2-tp-mx-auto'>
                            <img src={imgDAO} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 7 */}
        <section className='v2-pt-10 v2-tl-pt-8 v2-tp-pt-12 v2-m-pt-6 v2-pb-8 v2-tl-pb-6 v2-tp-pb-4 v2-m-pb-3 financial-7'>
            <Container>
                <Row className='align-center'>
                    <Col sm={5} className='v2-tp-col-12'>
                        <div className='img-wrap v2-tp-mw-50 v2-m-mw-70 mx-auto'>
                            <img src={imgOutlook} />
                        </div>
                    </Col>

                    <Col sm={7} className='v2-pl-6 v2-tp-col-12 v2-tp-pl-2 v2-tp-mt-2'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-m-font-28 bold white-text v2-tp-text-center'>Outlook</h2>
                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>Ovato is extremely confident that it can provide real utility and acceptance of its coin, offering a different approach to all those who use it in their regular lives. By addressing actual needs and providing tangible economic incentives for all users, Ovato can focus its energy on transactions and represent the citizens' underlying desire for an alternative means of exchanging and storing value. Plans call for Ovato to list on more mature exchanges, such as Binance and OpenSea, for its NFTs</p>

                        <div className='v2-mt--2 v2-tp-text-center v2-justify-content-center v2-m-flex-column flex align-center v2-mt-2 btn-wrap'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn'>Get a free wallet</a>
                            <div className='v2-mt--1 v2-ml--2 v2-m-ml-0'>
                                <QRFinancialOverview url={withAffCodeURL('https://wallet.ovato.com/') } size={600} />
                            </div>
                            {/* <img src={imgScan} width={200} className='v2-mt--2 v2-m-mt-0 v2-ml--2 v2-m-ml-0 desktop' />
                            <img src={imgScanMobile} width={150} className='mobile v2-mt--1 v2-ml-3' /> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

      </div>
    );
  }
}
export default withTranslation()(FinancialOverview);
